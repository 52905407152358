// Can be used during testing to turn off jQuery and CSS animation
;(window as any).disableAllAnimations = function () {
  document.querySelectorAll('div, a, span, footer, header').forEach((elem: HTMLElement) => {
    elem.style.transition = 'none'
  })
  document.querySelectorAll('.modal.in').forEach((elem: HTMLElement) => {
    elem.style.display = 'block'
  })
  document.querySelectorAll('.modal-backdrop').forEach((elem: HTMLElement) => {
    elem.style.display = 'none'
  })

  document.querySelectorAll("[data-controller='lottie']").forEach((lottieElement: HTMLElement) => {
    lottieElement.style.display = 'none'
  })
}
