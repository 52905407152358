import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  updateEmail,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  updateProfile,
} from 'firebase/auth'
import { initializeApp } from 'firebase/app'

import { rails_fetch } from '../util/rails_fetch'

// Remember to include firebase_init.slim before this file to include necessary variables
const firebaseApp = initializeApp((window as any).firebaseConfig)

function getCookie(name: string): string {
  return Object.fromEntries(document.cookie.split('; ').map((v) => v.split(/=(.*)/s).map(decodeURIComponent)))[name]
}

export default class LucaLogin {
  signInButton: HTMLButtonElement
  signOutButton: HTMLButtonElement
  mainSignOutButton: HTMLButtonElement
  signOutButtonMobile: HTMLButtonElement
  signInFacebookButton: HTMLButtonElement
  signInPasswordButton: HTMLButtonElement
  createWithPasswordButton: HTMLButtonElement
  sendEmailVerificationButton: HTMLButtonElement
  updateEmailButton: HTMLButtonElement
  alertDiv: HTMLElement
  alertText: HTMLElement
  creatingUser: boolean

  constructor() {
    this.signInButton = document.getElementById('sign-in-google') as HTMLButtonElement
    if (this.signInButton != null) {
      this.signInButton.addEventListener('click', this.signInGoogle.bind(this))
    }
    // We might have two sign out buttons on the page.
    this.signOutButton = document.getElementById('sign_out') as HTMLButtonElement
    if (this.signOutButton != null) {
      this.signOutButton.addEventListener('click', this.signOut.bind(this))
    }

    this.mainSignOutButton = document.getElementById('main-sign-out-button') as HTMLButtonElement
    if (this.mainSignOutButton != null) {
      this.mainSignOutButton.addEventListener('click', this.signOut.bind(this))
    }

    this.signOutButtonMobile = document.getElementById('sign_out_mobile') as HTMLButtonElement
    if (this.signOutButtonMobile != null) {
      this.signOutButtonMobile.addEventListener('click', this.signOut.bind(this))
    }
    this.signInFacebookButton = document.getElementById('sign-in-facebook') as HTMLButtonElement
    if (this.signInFacebookButton != null) {
      this.signInFacebookButton.addEventListener('click', this.signInFacebook.bind(this))
    }
    this.signInPasswordButton = document.getElementById('sign-in-password') as HTMLButtonElement
    if (this.signInPasswordButton != null) {
      this.signInPasswordButton.addEventListener('click', this.signInPassword.bind(this))
    }
    this.createWithPasswordButton = document.getElementById('create_password') as HTMLButtonElement
    if (this.createWithPasswordButton != null) {
      this.createWithPasswordButton.addEventListener('click', this.createWithPassword.bind(this))
    }
    this.sendEmailVerificationButton = document.getElementById('send-verification') as HTMLButtonElement
    if (this.sendEmailVerificationButton != null) {
      this.sendEmailVerificationButton.addEventListener('click', this.sendEmailVerificationClick.bind(this))
    }
    this.updateEmailButton = document.getElementById('update-email') as HTMLButtonElement
    if (this.updateEmailButton != null) {
      this.updateEmailButton.addEventListener('click', this.updateEmail.bind(this))
    }
    this.alertDiv = document.getElementById('alert-box') as HTMLElement
    this.alertText = document.getElementById('alert-text') as HTMLElement
  }

  listenToAuthChange() {
    // Initiates Firebase auth and listen to auth state changes.
    onAuthStateChanged(getAuth(firebaseApp), this.onAuthStateChanged.bind(this))
  }

  signInGoogle() {
    this.setSignupPathCookie()
    // Sign in Firebase using popup auth and Google as the identity provider.
    const provider = new GoogleAuthProvider()
    signInWithPopup(getAuth(firebaseApp), provider).catch((error) => {
      const errorCode = error.code
      const errorMessage = error.message
      console.log(error)
      switch (errorCode) {
        case 'auth/account-exists-with-different-credential':
          this.setAlert(I18n.t('authentication.account-exists-with-different-credential.google'))
          break
        default:
          this.setAlert(errorMessage)
      }
    })
  }

  signInFacebook() {
    this.setSignupPathCookie()
    const provider = new FacebookAuthProvider()
    signInWithPopup(getAuth(firebaseApp), provider).catch((error) => {
      const errorCode = error.code
      const errorMessage = error.message
      console.log(error)
      switch (errorCode) {
        case 'auth/account-exists-with-different-credential':
          this.setAlert(I18n.t('authentication.account-exists-with-different-credential.facebook'))
          break
        default:
          this.setAlert(errorMessage)
      }
    })
  }

  setAlert(msg) {
    this.alertDiv.classList.remove('d-none')
    return (this.alertText.innerHTML = msg)
  }

  signInPassword(event) {
    event.preventDefault()
    const email = (document.getElementById('email') as HTMLInputElement).value
    const password = (document.getElementById('password') as HTMLInputElement).value
    this.validateCredentials(email, password)

    return this.signInWithEmailAndPassword(email, password, false)
  }

  validateCredentials(email, password) {
    if (email.length < 4) {
      alert('Please enter an email address.')
      return
    }
    if (password.length < 4) {
      alert('Please enter a password.')
      return
    }
  }

  async createWithPassword(event) {
    event.preventDefault()

    const email = (document.getElementById('email') as HTMLInputElement).value
    const password = (document.getElementById('password') as HTMLInputElement).value

    if (email.length < 4) {
      alert('Please enter an email address.')
      return false
    }
    if (password.length < 4) {
      alert('Please enter a password.')
      return false
    }

    let result = await this.signOutBlockedUserCheck(email)
    if (result.status) {
      this.setAlert(I18n.t('mailers.verification_email.blocked_email'))
      return false
    } else {
      try {
        // We set creatingUser to true or else onAuthStateChanged will redirect us to verified_email before
        // createUserWithEmailAndPassword returns.
        this.creatingUser = true
        await createUserWithEmailAndPassword(getAuth(firebaseApp), email, password)
        let user = getAuth(firebaseApp).currentUser

        const fullnameElement = document.getElementById('fullname') as HTMLInputElement
        if (fullnameElement !== null) {
          const fullname = fullnameElement.value
          await updateProfile(user, { displayName: fullname })
        }

        document.cookie = `verify_email=${email}`
        await this.sendEmailVerification(user)
        if (window.location.pathname !== '/verify_email') {
          return (window.location.href = `/verify_email?lang=${getCookie('language')}`)
        } else if (window.location.pathname.startsWith('/user_invitations')) {
          return true
        }

        this.creatingUser = false
      } catch (error) {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        console.log(error)
        switch (errorCode) {
          case 'auth/email-already-in-use':
            return this.signInWithEmailAndPassword(email, password, true)
          default:
            this.setAlert(errorMessage)
        }
      }
    }
  }

  async signOutBlockedUserCheck(email: string) {
    const response = await fetch('/blocked_user?' + new URLSearchParams({ email: email }))
    return await response.json()
  }

  async sendEmailVerificationClick(event) {
    this.setAlert(I18n.t('notifications.resent_confirmation_message'))
    event.preventDefault()
    try {
      await this.sendEmailVerification(getAuth(firebaseApp).currentUser)
    } catch (error) {
      console.warn(error)
      this.setAlert(I18n.t('notifications.could_not_send_message_warning'))
    }
  }

  async updateEmail(event) {
    event.preventDefault()
    try {
      let email = (document.getElementById('email') as HTMLInputElement).value
      await updateEmail(getAuth(firebaseApp).currentUser, email)
      await this.sendEmailVerification(getAuth(firebaseApp).currentUser)
      window.location.href = '/verify_email'
    } catch (error) {
      console.warn(error)
      this.setAlert(error.message)
    }
  }

  async sendEmailVerification(user) {
    await this.setSignupPathCookie()

    await rails_fetch('/send_verification_email', {
      method: 'POST',
      body: JSON.stringify({
        lang: getCookie('language'),
        user: {
          name: user.displayName,
          email: user.email,
          uid: user.uid,
          invoicing_module: getCookie('subscription_type') === 'invoicing',
          holding_package: getCookie('subscription_type') === 'holding_package',
        },
      }),
    })
  }

  async signOut(event) {
    event.preventDefault()
    await signOut(getAuth(firebaseApp))
    if (window.location.pathname.startsWith('/found_company')) {
      window.location.href = '/found_company/authentications/sign_out_user'
    } else {
      window.location.href = '/sign_out_user'
    }
  }

  async onAuthStateChanged(user) {
    if (user) {
      if (window.location.pathname.startsWith('/found_company/authentications')) {
        return this.onAuthStateChangedFoundCompany(user)
      } else if (window.location.pathname.startsWith('/user_invitations')) {
        // we shouldn't redirect the user to the company page if he haven't accepted an invitation
      } else if (window.location.pathname.startsWith('/api_sign_in')) {
        return this.onAuthStateChangedApiSignIn(user)
      } else if (user.emailVerified) {
        return getAuth(firebaseApp)
          .currentUser.getIdToken(true)
          .then(function (idToken) {
            let urlWithParams = new URL(window.location.href)
            // User clicked the link for specific subcription via home page.
            const subType = urlWithParams.searchParams.get('sub_type')
            if (subType !== null) {
              document.cookie = `subscription_type=${subType}`
              window.location.href = `/companies/new?token=${encodeURIComponent(idToken)}`
            } else {
              window.location.href = `/?token=${encodeURIComponent(idToken)}`
            }
          })
          .catch(function (error) {
            // Handle error
          })
      } else if (user.email == null) {
        if (window.location.pathname !== '/edit_email') {
          return (window.location.href = '/edit_email')
        }
      } else {
        // In this case we have a not verified email, send to verify email
        // But not if we are currently creating a user (this is run immediately when creating a user, even
        // before the await method returns
        if (!this.creatingUser) {
          if (window.location.pathname !== '/verify_email') {
            return (window.location.href = `/verify_email?lang=${getCookie('language')}`)
          }
        }
      }
    }
  }

  async onAuthStateChangedApiSignIn(user) {
    return getAuth(firebaseApp)
      .currentUser.getIdToken(true)
      .then(async function (idToken) {
        let searchParams = new URL(window.location.href).searchParams
        const token = encodeURIComponent(idToken)
        window.location.href = `/api/v1/oauth2/codes/edit?token=${token}&${searchParams.toString()}`
      })
  }

  async onAuthStateChangedFoundCompany(user) {
    return getAuth(firebaseApp)
      .currentUser.getIdToken(true)
      .then(async function (idToken) {
        let searchParams = new URL(window.location.href).searchParams
        let submissionId = searchParams.get('submission_id')

        // Sign in the user
        if (submissionId != null) {
          window.location.href = `/found_company/submissions/${submissionId}?token=${encodeURIComponent(idToken)}`
        } else {
          window.location.href = `/found_company/submissions?token=${encodeURIComponent(idToken)}`
        }
      })
  }

  signInWithEmailAndPassword(email, password, registering) {
    signInWithEmailAndPassword(getAuth(firebaseApp), email, password).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code
      const errorMessage = error.message
      console.log(error)
      switch (errorCode) {
        case 'auth/user-not-found':
          this.setAlert(I18n.t('authentication.user_not_found'))
          break
        case 'auth/wrong-password':
          let message = I18n.t('authentication.wrong_password')
          if (registering) {
            message = I18n.t('authentication.email_in_use')
          }
          this.setAlert(message)
          break
        case 'auth/network-request-failed':
          this.setAlert(I18n.t('authentication.network_request_failed'))
          break
        case 'auth/account-exists-with-different-credential':
          this.setAlert(I18n.t('authentication.account-exists-with-different-credential.email'))
          break
        default:
          this.setAlert(errorMessage)
      }
    })
  }

  setSignupPathCookie() {
    let pageUrl = window.location.href
    let urlWithParams = new URL(pageUrl)
    let lastUrlSegment = pageUrl.substr(pageUrl.lastIndexOf('/') + 1).replace('#', '')
    if (pageUrl.includes('signup') && lastUrlSegment !== 'signup') {
      document.cookie = `signup=${lastUrlSegment}; path=/`
    }

    const subType = urlWithParams.searchParams.get('sub_type')
    if (subType !== null) {
      document.cookie = `subscription_type=${subType}`
    }

    let language = urlWithParams.searchParams.get('lang')
    if (language !== null && language !== undefined) {
      document.cookie = `language=${language}`
    }
  }
}
