import { Controller } from '@hotwired/stimulus'
import { rails_fetch } from '../util/rails_fetch'

import LucaLogin from '../authentication/LucaLogin'

function getCookie(name: string): string {
  return Object.fromEntries(document.cookie.split('; ').map((v) => v.split(/=(.*)/s).map(decodeURIComponent)))[name]
}

export default class extends Controller {
  static targets = ['error', 'submitButton']

  static values = { existingUser: Boolean }

  declare existingUserValue: boolean

  declare errorTarget: HTMLElement
  declare submitButtonTarget: HTMLButtonElement

  async accept(event) {
    event.preventDefault()

    this.disableButton(event.target)

    if (this.existingUserValue) {
      this.acceptInvitation()
    } else {
      let lucaLogin = new LucaLogin()
      let result = await lucaLogin.createWithPassword(event)
      if (result) {
        this.acceptInvitation()
      } else {
        this.enableButton()
      }
    }
  }

  async reject(event) {
    this.disableButton(event.target)
    this.rejectInvitation()
  }

  enableButton() {
    this.submitButtonTarget.disabled = false
    this.submitButtonTarget.textContent = I18n.t('authentication.accept_invite')
  }

  disableButton(button) {
    button.disabled = true
    button.textContent = I18n.t('generic.please_wait')
  }

  async acceptInvitation() {
    let url = new URL(window.location.href)
    let invitationId = url.pathname.split('/').slice(-1)[0]

    this.hideError()

    let response = await rails_fetch(`/user_invitations/${invitationId}/accept?lang=${getCookie('language')}`, {
      method: 'PATCH',
    })

    if (response.ok) {
      let responseData = await response.text()
      window.location.href = responseData
    } else {
      let errorData = await response.text()
      this.showError(errorData)
      this.enableButton()
    }
  }

  async rejectInvitation() {
    let url = new URL(window.location.href)
    let invitationId = url.pathname.split('/').slice(-1)[0]

    let response = await rails_fetch(`/user_invitations/${invitationId}/reject?lang=${getCookie('language')}`, {
      method: 'PATCH',
    })

    if (response.ok) {
      let responseData = await response.text()
      window.location.href = responseData
    }
  }

  showError(msg) {
    this.errorTarget.innerHTML = msg
    this.errorTarget.classList.remove('d-none')
  }

  hideError() {
    this.errorTarget.classList.add('d-none')
  }
}
