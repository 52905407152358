import { config, library, dom } from '@fortawesome/fontawesome-svg-core'
;(config as any).mutateApproach = 'sync'

import {
  faAbacus,
  faAlarmSnooze,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faArrowAltCircleRight,
  faArrowLeft,
  faArrowRight,
  faArrowsH,
  faArrowsV,
  faBalanceScale,
  faBan,
  faBinoculars,
  faBook,
  faBoxes,
  faBoxOpen,
  faBoxUsd,
  faBuilding,
  faCalendarCheck,
  faCalendarAlt,
  faCalendarExclamation,
  faCartPlus,
  faChartBar,
  faChartLine,
  faChartLineDown,
  faCheck,
  faCheckDouble,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faClock,
  faCog,
  faCogs,
  faCoins,
  faColumns,
  faCommentAltExclamation,
  faCommentLines,
  faCommentSmile,
  faCompressAlt,
  faCopy,
  faCloudUpload,
  faCreditCard,
  faDownload,
  faDebug,
  faEdit,
  faEquals,
  faEllipsisH,
  faEnvelope,
  faEnvelopeOpen,
  faExchange,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faExternalLink,
  faExternalLinkSquare,
  faFile,
  faFileAlt,
  faFileCheck,
  faFileCsv,
  faFileDownload,
  faFileEdit,
  faFileImport,
  faFileInvoiceDollar,
  faFilePdf,
  faFilePlus,
  faFileSearch,
  faFilter,
  faFingerprint,
  faFlame,
  faFileSignature,
  faFlagCheckered,
  faHandPointLeft,
  faHandPointRight,
  faHandPointDown,
  faFrown,
  faGift,
  faGlasses,
  faGlobe,
  faGrinBeam,
  faHandshake,
  faHome,
  faHourglassHalf,
  faIdCard,
  faInfo,
  faInfoCircle,
  faInfoSquare,
  faKey,
  faLineColumns,
  faLink,
  faListAlt,
  faLock,
  faLockOpen,
  faMagic,
  faMap,
  faMedal,
  faMeh,
  faMinus,
  faMinusCircle,
  faMoneyCheckAlt,
  faMoneyCheckEdit,
  faPaperclip,
  faPaperPlane,
  faPencil,
  faPercent,
  faPercentage,
  faPiggyBank,
  faPlay,
  faPlus,
  faPlusCircle,
  faPollH,
  faPowerOff,
  faQuestion,
  faQuestionCircle,
  faQuestionSquare,
  faReceipt,
  faRedoAlt,
  faRepeat,
  faSadTear,
  faSave,
  faSearch,
  faShareAlt,
  faSignIn,
  faSignOut,
  faSmile,
  faSms,
  faSortDown,
  faSortUp,
  faSpinner,
  faSpinnerThird,
  faStar,
  faStop,
  faSync,
  faTasks,
  faTable,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnlink,
  faUpload,
  faUserCheck,
  faUserCircle,
  faUserPlus,
  faUser,
  faUsers,
  faUsersCrown,
  faUserSlash,
  faSmileBeam,
  faSortAlt,
  faCommentDots,
  faSlash as faSlashFar,
} from 'fontawesome-luca/pro-regular-svg-icons'

import { faCat, faGrinHearts, faVolleyballBall } from 'fontawesome-luca/pro-light-svg-icons'

import {
  faAngleDown,
  faCircle,
  faArrowAltToBottom,
  faCheckCircle as faCheckCircleFas,
  faSlash as faSlashFas,
  faSquare,
} from 'fontawesome-luca/pro-solid-svg-icons'

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.

dom.watch()
library.add(
  faAbacus,
  faAlarmSnooze,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faAngleDown,
  faArrowAltCircleRight,
  faArrowLeft,
  faArrowRight,
  faArrowsH,
  faArrowsV,
  faBalanceScale,
  faBan,
  faBinoculars,
  faBook,
  faBoxes,
  faBoxOpen,
  faBoxUsd,
  faBuilding,
  faCalendarCheck,
  faCalendarAlt,
  faCalendarExclamation,
  faCartPlus,
  faCat,
  faChartBar,
  faChartLine,
  faChartLineDown,
  faCheck,
  faCheckDouble,
  faCircle,
  faCheckCircle,
  faCheckCircleFas,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faClock,
  faCog,
  faCogs,
  faCoins,
  faColumns,
  faCommentAltExclamation,
  faCommentLines,
  faCommentSmile,
  faCommentDots,
  faCompressAlt,
  faCopy,
  faCloudUpload,
  faCreditCard,
  faDownload,
  faDebug,
  faEquals,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faEnvelopeOpen,
  faExchange,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faExternalLink,
  faExternalLinkSquare,
  faFile,
  faFileAlt,
  faFileCheck,
  faFileCsv,
  faFileDownload,
  faFileEdit,
  faFileImport,
  faFileInvoiceDollar,
  faFilePdf,
  faFilePlus,
  faFileSearch,
  faFileSignature,
  faFilter,
  faFingerprint,
  faFlagCheckered,
  faFlame,
  faFrown,
  faGift,
  faGlasses,
  faGlobe,
  faGrinBeam,
  faGrinHearts,
  faHandPointLeft,
  faHandPointRight,
  faHandPointDown,
  faHandshake,
  faHome,
  faHourglassHalf,
  faHome,
  faIdCard,
  faInfo,
  faFileImport,
  faInfoCircle,
  faInfoSquare,
  faKey,
  faLineColumns,
  faLink,
  faListAlt,
  faLock,
  faLockOpen,
  faMagic,
  faMap,
  faMedal,
  faMeh,
  faMinus,
  faMinusCircle,
  faMoneyCheckAlt,
  faMoneyCheckEdit,
  faPaperclip,
  faPaperPlane,
  faPencil,
  faPercent,
  faPercentage,
  faPiggyBank,
  faPlay,
  faPlus,
  faPlusCircle,
  faPollH,
  faPowerOff,
  faQuestion,
  faQuestionCircle,
  faQuestionSquare,
  faReceipt,
  faRedoAlt,
  faRepeat,
  faSadTear,
  faSave,
  faSearch,
  faShareAlt,
  faSignIn,
  faSignOut,
  faSlashFar,
  faSlashFas,
  faSmile,
  faSms,
  faSortDown,
  faSortUp,
  faSpinner,
  faSpinnerThird,
  faSquare,
  faStar,
  faStop,
  faSync,
  faTable,
  faTasks,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnlink,
  faUpload,
  faArrowAltToBottom,
  faUserCheck,
  faUserCircle,
  faUserPlus,
  faUser,
  faUsers,
  faUsersCrown,
  faUserSlash,
  faSmileBeam,
  faSortAlt,
  faVolleyballBall,
)
